body {
	overflow-x: hidden;
	margin: 0px;
	min-height: 100vh;
	min-height: calc(var(--vh) * 100);
	min-height: -webkit-fill-available;
	/* position: fixed; */
	height: 100%;
	width: 100%; 
	background: linear-gradient(180deg, rgba(233, 219, 240, 0.4) 0%, rgba(249, 225, 228, 0.4) 100%)
}

* {
	box-sizing: border-box;
}


html::-webkit-scrollbar,
body::-webkit-scrollbar,
.root::-webkit-scrollbar {
	display: none;
}

body {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

a {
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
	text-decoration-color: rgba(249, 225, 228, 1);
}

@keyframes fadeIn {  
	from {  
		opacity:0;  
	}  
 
	to {  
		opacity:1;  
	}  
 }

 .carousel .control-dots {
	top: 0!important;
    margin: 6px 0!important;
	display: flex!important;
 }

 .carousel .control-dots .dot {
	background: rgba(217, 217, 217, 1)!important;
    border-radius: 16px!important;
    width: 100%!important;
    height: 5px!important;
	margin: 0 4px!important;
 }

 .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
	background-color: rgba(221, 110, 63, 1)!important;
 }

 .carousel .carousel-status {
	display: none!important;
 }

 .carousel .thumbs-wrapper {
	margin: 0px!important;
 }

 /* Hide the scrollbar */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Optional: Provide a fallback color for scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: transparent;
}

#invisible-button {
  display: none;
  height: 0px!important;
  width: 0px!important;
}