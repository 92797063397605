.swipe-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
  /* height: 100%; */
}

.swipe-card__cards {
  position: relative;
  width: 100%;
  height: 100%;
}

.swipe-card__container {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 95%;
  /* height: calc(100vh - 44px); */
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  cursor: grab;
  user-select: none;
  transform: scale(1) translateY(0px);
  transition: all 0.5s;
}

/* .swipe-card__container:first-of-type {
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
} */

.swipe-card__container:last-of-type {
  pointer-events: auto;
  /* filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2)); */
  transform: scale(1) translateY(0px);
}

.swipe-card__header {
  display: flex;
}

.swipe-card__ribbons-container {
  position: absolute;
  width: 100%;
}

.swipe-card__ribbon-like,
.swipe-card__ribbon-dislike {
  position: absolute;
  opacity: 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 60px;
  font-weight: 700;
  font-size: 2rem;
  top: 3rem;
  z-index: 50;
  padding-block: 0.25rem;
  padding-inline: 0.5rem;
  border-radius: 0.375rem;
  transition: all 0.4s;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.25);
}

.swipe-card__ribbon-like {
  left: 2rem;
  background-color: #4ade80;
  transform: rotate(-20deg);
  text-shadow: -2px 0px 1px rgba(0, 0, 0, 0.2);
}

.swipe-card__ribbon-dislike {
  right: 2rem;
  background-color: #f87171;
  transform: rotate(20deg);
  text-shadow: 2px 0px 1px rgba(0, 0, 0, 0.2);
}

.swipe-card__ribbon-like.show,
.swipe-card__ribbon-dislike.show {
  opacity: 1;
}

.swipe-card__image-container {
  position: relative;
  height: 400px;
}

.swipe-card__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swipe-card__children {
  bottom: 0;
  display: flex;
  justify-content: center;
  gap: 4vw;
  align-items: center;
  position: absolute;
  bottom: 4vh;
  margin-bottom: 22px;
  width: 100%;
  transition: all .7s ease;
  opacity: 1;
}

.swipe-card__children.hide-action-buttons {
  opacity: 0;
}

.swipe-card__action-button {
  height: 65px;
  width: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px rgba(71, 71, 71, 0.2);
  cursor: pointer;
}

.swipe-card__empty-state {
  width: 100%;
  height: 100%;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  transition: all 0.5s ease;
}

.swipe-card__empty-state.swipe-card__empty-state.show-empty-state {
  transform: scale(1);
  opacity: 1;
}

@media (max-height: 750px) {
  .swipe-card__container {
    /* transform: scale(0.95) translateY(25px); */
    transform: scale(1) translateY(0px);
  }
}

.swipe-card__custom-action-button {
  display: flex;
  width: 60px;
  height: 60px;
  background: #DD6E3F;
  align-items: center;
  z-index: 1;
  color: white;
  justify-content: center;
  border-radius: 50%;
}

@media (max-height: 590px) {
  .swipe-card__custom-action-button {
    width: 50px;
    height: 50px;
  }
}

@media (max-height: 450px) {
  .swipe-card__custom-action-button {
    width: 40px;
    height: 40px;
  }
}

.loveProduct {
  border: 2px solid #6de5b7;
  background: white;
}

.dislikeProduct {
  background: white;
  border: 2px solid #eb5475;
}

.similarProduct {
  width: 40px;
  height: 40px;
  border: 2px solid #85c6e2;
  background: white;
}

.saveProduct {
  width: 40px;
  height: 40px;
  border: 2px solid rgba(255, 200, 43, 1)
;
  background: white;
}

.badge-product-match {
  display: flex;
  position: absolute;
  text-align: center;
  top: 26px;
  left: -14px;
  z-index: 2;
  min-width: 100px;
}

.badge-product-match span {
  position: relative;
  background: rgba(133, 198, 226, 1);
  color: #fff; 
  font-size: 14px;
  padding: 3px 12px;
  font-family: Inter;
}

.badge-product-match span::before {
  border: 5px solid rgba(133, 198, 226, 1);
  border-color: rgba(133, 198, 226, 1) transparent transparent rgba(133, 198, 226, 1);
  border-width: 9px 5px;
  position: absolute;
  right: -9px;
  top: 0;
  content: "";
  z-index: 1;
}

.badge-product-match span::after {
  border: 5px solid rgba(133, 198, 226, 1);
  border-color: transparent transparent rgba(133, 198, 226, 1) rgba(133, 198, 226, 1);
  border-width: 10px 5px;
  position: absolute;
  right: -9px;
  bottom: 0;
  content: "";
  z-index: 1;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none!important;
}

.carousel .control-prev.control-arrow {
  display: none!important;
}

.carousel .control-next.control-arrow {
  width: 100%!important;
}

.carousel .control-next.control-arrow:before {
  display: none!important;
}

.full-screen {
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.disliked {
  background: linear-gradient(180deg, #EA4080 0%, #EE805F 68.16%)
}

.liked {
  background: linear-gradient(180deg, #6DE5B5 0%, #73ECDD 100%);
}